<template>
    <v-container fluid class="px-6 py-6">
        <v-row class="mb-12">
            <v-col md="12">
                <datatable-full
                    :data="serversData"
                    :tableSettings="tableSettings"
                    :isDataLoading="isServersDataLoading"
                    :dataError="serversDataError"
                    :header="$t('servers.table.header')"
                    :subheader="$t('servers.table.subheader')"
                    @addItem="addServer"
                    @editItem="editServer"
                    @deleteItem="deleteServer"
                >
                    <template #last24h_status="{ item }">
                        {{ item.last24h_status }}%
                    </template>
                </datatable-full>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatatableFull from '@/components/Tables/DatatableFull.vue';

import { getServer, addServer, deleteServer, editServer } from '@/api';

export default {
    name: 'Servers',
    components: {
        DatatableFull,
    },
    data() {
        return {
            serversData: null,
            isServersDataLoading: true,
            serversDataError: false,
            tableSettings: {
                headers: [
                    {
                        text: this.$t('servers.table.tableHeader.host'),
                        align: 'start',
                        cellClass: 'border-bottom',
                        value: 'host',
                        class: 'text-secondary font-weight-bolder opacity-7 border-bottom ps-6',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.ip'),
                        value: 'ip',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.type'),
                        value: 'type',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.status'),
                        value: 'current_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        width: '90px',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.last24h'),
                        value: 'last24h_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '130px',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.actions'),
                        value: 'actions',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '120px',
                    },
                ],
                editedItem: {
                    host: '',
                    ip: '',
                    type: '',
                },
                editedItemFields: {
                    host: {
                        name: `${this.$t('servers.table.tableHeader.host')} *`,
                    },
                    ip: {
                        name: `${this.$t('servers.table.tableHeader.ip')} *`,
                    },
                    type: {
                        name: `${this.$t('servers.table.tableHeader.type')} *`,
                        type: 'select',
                        options: [
                            'Debian',
                            'Debian / Plesk',
                            'Ubuntu',
                            'Ubuntu / Plesk',
                        ],
                    },
                },
            },
        };
    },
    methods: {
        getServer: async function () {
            try {
                this.isServersDataLoading = true;
                this.serversData = await getServer();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Loaded servers');
                }
            } catch (error) {
                this.serversDataError = true;
                console.error(error);
            } finally {
                this.isServersDataLoading = false;
            }
        },
        addServer: async function (data) {
            try {
                await addServer(data);
                this.getServer();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Added server', data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        editServer: async function (data) {
            try {
                await editServer(data);
                this.getServer();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Edited server', data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        deleteServer: async function (id) {
            try {
                await deleteServer(id);
                this.getServer();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Deleted server with id: ', id);
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted: async function () {
        await this.getServer();
    },
};
</script>
